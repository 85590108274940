import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Pricing from "components/pricing/ThreePlans.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/FiveColumnWithBackground.js";
import iOSImageSource from "images/ios.png";
import androidImageSource from "images/android.png";
import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";

const basePlanFeatures = [
  "Push Notifications",
  "Pull To Refresh",
  "Downloads & Uploads",
  "App Linking & Sharing",
  "Media Playback",
  "24/7 Support",
];

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage>
      <Hero roundedHeaderButton={true} />
      <div id="#features">
        <Features
          subheading={<Subheading>Features</Subheading>}
          heading={
            <>
              We have Amazing <HighlightedText>Service.</HighlightedText>
            </>
          }
          description=""
        />
      </div>
      <MainFeature
        heading={"iOS App"}
        subheading=""
        description="We convert your website to an iOS mobile application and send you the app ready to launch, so you can submit it to the App Store."
        imageSrc={iOSImageSource}
        imageBorder={true}
        imageDecoratorBlob={true}
      />
      <MainFeature
        heading={"Android App"}
        subheading=""
        description="We convert your website to an android mobile application and send you the app (.apk file) ready to launch.        "
        imageSrc={androidImageSource}
        imageBorder={true}
        imageDecoratorBlob={true}
        textOnLeft={false}
      />
      <FeatureWithSteps
        subheading={<Subheading>STEPS</Subheading>}
        heading={
          <>
            Easy to <HighlightedText>Get Started.</HighlightedText>
          </>
        }
        textOnLeft={false}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
        centerWithNoImage={true}
        steps={[
          {
            heading: "Submit your website URL",
            description:
              "Enter the website you would like to convert into a native app.",
          },
          {
            heading: "We deliver your app",
            description:
              "In less than 24 hours our team will develop your app and deliver it to your inbox.",
          },
          {
            heading: "Upload to App Store or Play Store",
            description:
              "You’re all set! Simply upload your app to the App Store or Play Store.",
          },
        ]}
      />
      <Pricing
        subheading={<Subheading>Pricing</Subheading>}
        heading={
          <>
            Reasonable & Flexible <HighlightedText>Plans.</HighlightedText>
          </>
        }
        plans={[
          {
            name: "Android",
            price: "$39",
            duration: "One Time Payment",
            mainFeature: "Build an APK file for the Play Store",
            features: [...basePlanFeatures],
            value: "android",
          },
          {
            name: "Android & iOS",
            price: "$99",
            duration: "One Time Payment",
            mainFeature: "Build both Android & iOS application",
            features: [...basePlanFeatures],
            featured: true,
            value: "ios",
          },
          {
            name: "iOS",
            price: "$69",
            duration: "One Time Payment",
            mainFeature: "Build an IPA file for the App Store",
            features: [...basePlanFeatures],
            value: "full",
          },
        ]}
      />
      <FAQ
        subheading={<Subheading>FAQS</Subheading>}
        description={"And we have got answers to all of them."}
        heading={
          <>
            You have <HighlightedText>Questions ?</HighlightedText>
          </>
        }
        faqs={[
          {
            question: "What is WebsiteTo.App?",
            answer:
              "WebsiteTo.App is a product that converts your website into a fully functional mobile app for iOS or Android in less than 24 hours. WebsiteTo.App is backed by a team of individuals who came together to solve the problem known as mobile app development. ",
          },
          {
            question: "What do I need to create my app?",
            answer:
              "You just need to send us your website URL, App Name and Logo.",
          },
          {
            question: "When will my application be ready?",
            answer:
              "It takes us less than 24hrs to deliver your application to your inbox.",
          },
          {
            question: "How will my application look?",
            answer:
              "Your application will look exactly as your website on mobile devices without the browser interface. You can check examples here.",
          },
          {
            question: "How do app updates work?",
            answer:
              "You don't need to update your application. Every change you make on your website will reflect on the app, you don't need to convert it again.",
          },
          {
            question: "How many times do I have to pay?",
            answer:
              "You only have to pay once to convert one website. There are no monthly or yearly fees. Yes! It is just a one time payment. You can check our pricing here.",
          },
          {
            question: "Do you provide Push Notifications?",
            answer:
              "Yes, we do provide Push Notifications for iOS and Android.",
          },
          {
            question: "How will I receive my application?",
            answer:
              "Your application will be delivered to you via email (to your inbox).",
          },
          {
            question: "Can I publish the app on App store and Google play?",
            answer:
              "Yes, absolutely. Once you receive your application, you can publish it without any problem. We will provide the documentation and also video tutorials step by step. It is a really easy process, you will be able to publish the apps in 15 minutes . In case you need help, our team is able to assist you anytime 24 hours.",
          },
          {
            question: "How do I contact WebsiteTo.App?",
            answer:
              "We are an support@websiteto.app away in case you need something.",
          },
        ]}
      />
      <GetStarted text="Easily turn your website into a mobile app with our website to app converter." />
      <Footer />
    </AnimationRevealPage>
  );
};
