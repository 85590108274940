import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
// eslint-disable-next-line
import { css } from "styled-components/macro";
import GitHubButton from "react-github-btn";

import { LogoLink } from "components/headers/light.js";
import { SectionHeading as HeadingBase } from "components/misc/Headings";
import { SectionDescription as DescriptionBase } from "components/misc/Typography";
import illustration from "images/login-illustration.svg";

import logo from "images/logo.svg";

/* Hero */
const Row = tw.div`flex`;
const NavRow = tw(Row)`flex flex-col lg:flex-row items-center justify-between`;
const NavLink = tw.a`mt-4 lg:mt-0 transition duration-300 font-medium pb-1 border-b-2 lg:mr-12 last:mr-0 text-gray-700 border-gray-400 hocus:border-gray-700 `;
const PrimaryNavLink = tw(
  NavLink
)`text-gray-100 bg-primary-500 px-6 py-3 border-none rounded hocus:bg-primary-900 focus:shadow-outline`;
const HeroRow = tw(
  Row
)`max-w-xl flex-col justify-between items-center py-20 lg:py-24 mx-auto`;

const Container = tw(
  ContainerBase
)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-2xl`;
const FormContainer = tw.div`w-full flex-1`;

const Form = tw.form`mx-auto max-w-xs`;

const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-primary-100 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${(props) => `background-image: url("${props.imageSrc}");`}
  ${tw`m-12 xl:m-16 w-full max-w-sm bg-contain bg-center bg-no-repeat`}
`;

const EmptySpace = tw.div`mb-5`;
const Description = tw(
  DescriptionBase
)`mt-4 text-center lg:text-base text-gray-700 max-w-lg mx-auto lg:mx-0`;

const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;

export default () => {
  return (
    <AnimationRevealPage>
      <Container>
        <Content>
          <MainContainer>
            <MainContent>
              <HeroRow>
                <Heading>Thank You!</Heading>
                <Description tw="mt-12">
                  Thank you for ordering <b>websiteto.app</b> to convert your
                  website into a mobile app. We understand that this is an
                  important decision and we appreciate your trust in us. Our
                  team will review your order and will be in touch with you
                  within 24 hours to discuss next steps. We are excited to work
                  with you and bring your website to the mobile platform. Thank
                  you again for choosing us, and please let us know if you have
                  any questions or concerns in the meantime.
                </Description>
              </HeroRow>
              <SubmitButton
                type="button"
                onClick={(e) => {
                  window.location.replace("https://wa.me/+447915456681");
                }}
              >
                <span className="text">{`Contact Us (7/24 Live Support)`}</span>
              </SubmitButton>
            </MainContent>
          </MainContainer>
          <IllustrationContainer>
            <IllustrationImage imageSrc={illustration} />
          </IllustrationContainer>
        </Content>
      </Container>
    </AnimationRevealPage>
  );
};
