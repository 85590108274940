const packages = [
  {
    label: "Android",
    value: "android",
    price: 39,
  },
  {
    label: "iOS",
    value: "ios",
    price: 69,
  },
  {
    label: "Android & iOS",
    value: "full",
    price: 99,
  },
];

const codePrice = 59;

export {
  packages,
  codePrice,
}