import React, { useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import illustration from "images/login-illustration.svg";
import logo from "images/logo.svg";
import googleIconImageSrc from "images/google-icon.png";
import twitterIconImageSrc from "images/twitter-icon.png";
import { ReactComponent as LoginIcon } from "feather-icons/dist/icons/log-in.svg";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";
import { useEffect } from "react";
import { packages, codePrice } from "constants/pricing";
import { isEmail, isValidURL } from "utils/utils";
import AppLogo from "../components/headers/appLogo";

const Container = tw(
  ContainerBase
)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-2xl`;
const FormContainer = tw.div`w-full flex-1 mt-8`;

const SocialButtonsContainer = tw.div`flex flex-col items-center`;
const SocialButton = styled.a`
  ${tw`w-full max-w-xs font-semibold rounded-lg py-3 border text-gray-900 bg-gray-100 hocus:bg-gray-200 hocus:border-gray-400 flex items-center justify-center transition-all duration-300 focus:outline-none focus:shadow-outline text-sm mt-5 first:mt-0`}
  .iconContainer {
    ${tw`bg-white p-2 rounded-full`}
  }
  .icon {
    ${tw`w-4`}
  }
  .text {
    ${tw`ml-4`}
  }
`;

const DividerTextContainer = tw.div`my-12 border-b text-center relative`;
const DividerText = tw.div`leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform -translate-y-1/2 absolute inset-x-0 top-1/2 bg-transparent`;

const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-primary-100 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${(props) => `background-image: url("${props.imageSrc}");`}
  ${tw`m-12 xl:m-16 w-full max-w-sm bg-contain bg-center bg-no-repeat`}
`;

const EmptySpace = tw.div`mb-5`;

let data = {
  name: "",
  url: "",
  email: "",
  package: packages[0],
  code: false,
};

export default ({
  logoLinkUrl = "#",
  illustrationImageSrc = illustration,
  headingText = "App Details",
  socialButtons = [
    {
      iconImageSrc: googleIconImageSrc,
      text: "Sign In With Google",
      url: "https://google.com",
    },
    {
      iconImageSrc: twitterIconImageSrc,
      text: "Sign In With Twitter",
      url: "https://twitter.com",
    },
  ],
  forgotPasswordUrl = "#",
  signupUrl = "#",
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  useEffect(() => {
    data.url = searchParams.get("url");
  });

  const [selectedPackage, setPackage] = useState(packages[0]);
  const [includeSourceCode, setSourceCode] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  return (
    <AnimationRevealPage>
      <Container>
        <Content>
          <MainContainer>
            <MainContent>
              <AppLogo />
              <FormContainer>
                <Form>
                  <Input
                    type="name"
                    placeholder="App Name"
                    onChange={(e) => {
                      data["name"] = e.target.value;
                    }}
                    required
                  />
                  <Input
                    type="email"
                    placeholder="Email"
                    onChange={(e) => (data["email"] = e.target.value)}
                    required
                  />
                  <Input
                    type="url"
                    placeholder="Website URL"
                    defaultValue={searchParams.get("url")}
                    onChange={(e) => (data["url"] = e.target.value)}
                    required
                  />
                  <EmptySpace />
                  <FormGroup>
                    {packages.map((value) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedPackage.value === value.value}
                            onChange={(e, newValue) => {
                              setPackage(value);
                              data["package"] = value;
                            }}
                            color="primary"
                          />
                        }
                        label={value.label}
                      />
                    ))}
                  </FormGroup>
                  <DividerTextContainer>
                    <DividerText>Additional options</DividerText>
                  </DividerTextContainer>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e, newValue) => {
                          setSourceCode(newValue);
                          data["code"] = newValue;
                        }}
                        color="primary"
                      />
                    }
                    label={`Include Source Code ($${codePrice})`}
                  />
                  {errorMsg ? <div>{errorMsg}</div> : <div></div>}
                  <SubmitButton
                    type="button"
                    onClick={(e) => {
                      if (!data.name) {
                        setErrorMsg("Please enter a your application name");
                        return;
                      }

                      if (!data.email || !isEmail(data.email)) {
                        setErrorMsg("Please enter a valid email");
                        return;
                      }

                      if (!data.url || !isValidURL(data.url)) {
                        setErrorMsg("Please enter a valid URL");
                        return;
                      }

                      localStorage.setItem("order", JSON.stringify(data));

                      navigate("/checkout");
                    }}
                  >
                    <span className="text">{`Checkout ${
                      includeSourceCode
                        ? selectedPackage.price + codePrice
                        : selectedPackage.price
                    }$`}</span>
                  </SubmitButton>
                </Form>
              </FormContainer>
            </MainContent>
          </MainContainer>
          <IllustrationContainer>
            <IllustrationImage imageSrc={illustrationImageSrc} />
          </IllustrationContainer>
        </Content>
      </Container>
    </AnimationRevealPage>
  );
};
