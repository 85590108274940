import React, { useEffect, useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import illustration from "images/login-illustration.svg";
import { useNavigate } from "react-router-dom";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { codePrice, packages } from "../constants/pricing";

import CheckoutComponents from "../components/features/CheckoutComponents";
import {
  AttachMoney,
  Language,
  Mail,
  PhoneIphone,
  Smartphone,
} from "@material-ui/icons";
import AppLogo from "../components/headers/appLogo";

const Container = tw(
  ContainerBase
)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-2xl`;
const FormContainer = tw.div`w-full flex-1`;

const Form = tw.form`mx-auto max-w-xs`;

const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-primary-100 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${(props) => `background-image: url("${props.imageSrc}");`}
  ${tw`m-12 xl:m-16 w-full max-w-sm bg-contain bg-center bg-no-repeat`}
`;

const EmptySpace = tw.div`mb-5`;

export default ({ logoLinkUrl = "#", illustrationImageSrc = illustration }) => {
  const navigate = useNavigate();

  const [currentData, setCurrentData] = useState(null);

  useEffect(() => {
    try {
      const orderItem = localStorage.getItem("order");
      console.log(`order item: ${orderItem}`);
      if (!orderItem) {
        navigate("/create-order");
        return;
      }

      setCurrentData(getCurrentData());
      setPrice(getProductPrice());
      setDesc(getProductDescription());
    } catch (error) {}
  });

  const getCurrentData = () => JSON.parse(localStorage.getItem("order"));

  const getProductDescription = () => {
    let currentData = getCurrentData();

    let description = `App Name: ${currentData.name}\nWebsite URL: ${currentData.url}\nEmail: ${currentData.email}\nPackage: ${currentData.package.label}\nInclude Source Code: ${currentData.code}`;

    return description;
  };

  const getProductPrice = () => {
    let currentData = getCurrentData();

    const currentPackageID = currentData.package.value;

    const foundPackage = packages.find(
      (element) => element.value === currentPackageID
    );

    let price = foundPackage.price;

    if (currentData.code) {
      price += codePrice;
    }

    return price;
  };

  const [description, setDesc] = useState(getProductDescription());

  const [price, setPrice] = useState(getProductPrice());

  return (
    <AnimationRevealPage>
      <Container>
        <Content>
          <MainContainer>
            <MainContent>
              <AppLogo />
              <FormContainer>
                <Form>
                  <CheckoutComponents
                    features={[
                      {
                        Icon: PhoneIphone,
                        title: "App Name",
                        description: `${currentData?.name}`,
                        iconContainerCss: tw`bg-primary-900 text-white`,
                      },
                      {
                        Icon: Language,
                        title: "Website URL",
                        description: `${currentData?.url}`,
                        iconContainerCss: tw`bg-primary-900 text-white`,
                      },

                      {
                        Icon: Mail,
                        title: "Contact Mail",
                        description: `${currentData?.email}`,
                        iconContainerCss: tw`bg-primary-900 text-white`,
                      },

                      {
                        Icon: Smartphone,
                        title: "Package",
                        description: `${currentData?.package?.label}`,
                        iconContainerCss: tw`bg-primary-900 text-white`,
                      },

                      {
                        Icon: AttachMoney,
                        title: "Total",
                        description: `$${price} ${
                          currentData?.code ? "(Includes Source Code)" : ""
                        }`,
                        iconContainerCss: tw`bg-primary-900 text-white`,
                      },
                    ]}
                  />
                  <EmptySpace />
                  <PayPalScriptProvider
                    options={{
                      "client-id":
                        "AbhpRg670iq689Ek8N2OIZZPTa6r5QcvLQX0fFJNr2CX3DvPzk4DD4-nqUrNRgg4t8gX3f3EL0_NQMTq",
                      currency: "USD",
                    }}
                  >
                    <PayPalButtons
                      createOrder={(data, actions) => {
                        return actions.order.create({
                          purchase_units: [
                            {
                              description: description,
                              amount: {
                                value: price,
                              },
                            },
                          ],
                        });
                      }}
                      onApprove={(data, actions) => {
                        return actions.order.capture().then((details) => {
                          window.conversationEvent();
                          navigate("/thank-you");
                        });
                      }}
                      style={{ layout: "horizontal", label: "checkout" }}
                    />
                  </PayPalScriptProvider>
                </Form>
              </FormContainer>
            </MainContent>
          </MainContainer>
          <IllustrationContainer>
            <IllustrationImage imageSrc={illustrationImageSrc} />
          </IllustrationContainer>
        </Content>
      </Container>
    </AnimationRevealPage>
  );
};
