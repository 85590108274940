import logo from "../../images/logo.svg";
import { LogoLink } from "./light";

export default () => {
  return (
    <LogoLink href="/">
      <img src={logo} alt="logo" />
      WebsiteTo.App
    </LogoLink>
  );
};
