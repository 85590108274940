import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({ headingText = "Terms of Use" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>
              By using WebsiteTo.App, you are allowing to be bound by our
              website’s Terms and Conditions, all relevant laws and regulations,
              and you are liable to agree with any applicable local laws. These
              are WebsiteTo.App's terms of use and apply to all users of this
              website and mobile applications. ‘We’, ‘our’ or ‘us’ refers to
              WebsiteTo.App. You will be forbidden from using this site if you
              do not agree with any of these terms. Copyright and trademark law
              protects all materials used on this website. You also agree to our
              privacy policy also written in this section. We may make changes
              to these terms. If you continue to use WebsiteTo.App after any
              modifications to the terms, it will indicate that you have
              accepted the revised terms.
            </p>
            <h2>License</h2>
            <ol>
              <li>
                Once you pay, you have to wait less than 24 hrs to receive your
                app.
              </li>
              <li>You pay for 1 app at a time.</li>
              <li>
                Make sure your website is mobile responsive for better UX.
              </li>
              <li>We do not make discounts.</li>
              <li>
                You accept to give us your website URL and logo so that we make
                the app.
              </li>
              <li>
                You accept to give us your email and receive a mail when your
                app is ready.
              </li>
              <li>
                Do not modify or copy the materials on this website. You can
                only utilize the materials for any commercial purpose, or for
                any public display.
              </li>
              <li>
                Do not attempt to decompile or reverse engineer any software
                included on WebsiteTo.App site.
              </li>
            </ol>
            <h2>Revisions and Errata</h2>
            <p>
              WebsiteTo.App website may contain elements which comprise of
              technical, typographical, or sometimes photographic errors. We do
              not guarantee that any of the contents on our site are genuine,
              comprehensive, or contemporary. WebsiteTo.App can make changes to
              the materials received on its website at any time without
              notification. However, we do not make any commitment to refresh
              the contents.
            </p>
            <h2>Links</h2>
            <p>
              WebsiteTo.App has not studied all of the sites associated with its
              Internet web site and is not liable for the elements of any such
              linked site. The addition of any link does not indicate approval
              by WebsiteTo.App site. Handling of any such linked web site is at the
              user's own risk.
            </p>
            <h2>Refund Policy</h2>
            <p>
              Once you order your apps, we pay our highly trained developers to
              successfully convert your website to an app. Therefore, WebsiteTo.App
              does not provide refunds.
            </p>
            <h2>Privacy Policy</h2>
            <p>
              At WebsiteTo.App, we recognize that privacy of your personal
              information is important. We never sell to third parties (any site
              or program or anyone) your personal information we receive and
              collect when you use and visit WebsiteTo.App. We do not use cookies
              and do not run ads to keep our customers as private as possible.
            </p>
            <h1>Contact Us</h1>
            <p>
              If you have any questions about these Terms and Conditions, You
              can contact us:
            </p>
            <ul>
              <li>Phone Number: +447915456681</li>
            </ul>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
